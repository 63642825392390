<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha uma tag abaixo para editar ou excluir.
        </p>
        <v-btn v-if="tagCriar" small depressed color="fmq_gray" dark to="/tags-criar"
          >Nova Tag</v-btn
        >
      </div>
      <TagsTabela
        :headers="headers"
        :tags="tags"
        :loading="loading"
        :total="totalTags"
        :pageText="pageText"
        :pageNumber="page"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:nome>
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome da tag"
            solo
            flat
            dense
            :hide-details="true"
            @input="page = 1;getTags(formData, page)"
            :clearable="true"
            :full-width="true"
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </TagsTabela>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Tag excluida com sucesso"
      @close="
        getTags(formData, page);
        success = false;
        clear();
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import TagsTabela from "@/components/tags/TagsTabela.vue";
import { buscarTag, excluirTag } from "@/services/tags";
export default {
  name: "Tags",
  components: { TagsTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Tags",
        disabled: true,
        to: "",
      },
    ],
    headers: [
      { text: "Tag", value: "nome", width: "90%", sortable: false  },
      { text: "Ações", value: "id", sortable: false },
    ],
    loading: false,
    tags: [],
    totalTags: null,
    pageText: null,
    page: 1,
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      nome: null,
    },
    dialog: false,
    dialogMessage: null,
    tagsId: null,
    error: false,
    success: false,
    messageError: null,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    if (this.$store.state.tagsSearch.length) {
      this.formData = { ...this.$store.state.tagsSearch[0] };
    }
    this.getTags(this.formData, this.page);
  },
  methods: {
    async getTags(payload, page) {
      this.produtos = [];
      this.$store.dispatch("setTagsSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarTag(payload, page);
        this.tags = resp.data.data;
        this.totalTags = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setTagsSearch", {});
      this.formData.nome = null;
      this.page = 1;
      this.getTags(this.formData, this.page);
    },
    search() {
      this.page = 1;
      this.getTags(this.formData, this.page);
    },
    filter(event) {
      this.$store.dispatch("setTagsSearch", this.formData);
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.getTags(this.formData, this.page);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirTag(this.tagId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.tagId = item.id;
    },
  },
  computed: {
    tagCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "tag.criar"
      );
    },
  },
};
</script>

<style></style>
